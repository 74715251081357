<template>
  <div class="mb-20" v-if="componentContent.count !== 1 || !videoAd || isNative">
    <AdWrapper :ad="{ slotId: isNative ? 'mob_nat_responsive' : 'mob_intext_ad_' + adId }" grey-background />
    <AdWrapper :ad="{ slotId: isNative ? 'nat_dsk_responsive' : 'dsk_intext_ad_' + adId }" grey-background />
  </div>
  <div class="bg-white -mx-20 lg:mx-0" v-else>
    <AllerVisibilityLoader>
      <h2 class="text-12 leading-20 uppercase font-roboto_condensed px-10">{{ playlistHeader }}</h2>
      <div class="mt-5 lg:mt-0 pb-30">
        <LazyVideoJwPlayer @playlist-loaded="playlistLoaded" :related="false" :player-id="config.jwPlayer.playerIds.recommended" :media="{ id: config.jwPlayer.playlists.mostViewed }" :setup-override="playerSetupOverride" />
      </div>
    </AllerVisibilityLoader>
  </div>
</template>
<script setup lang="ts">
const props = defineProps({
  componentContent: {
    type: Object as PropType<Aller.ContentComponentAd>,
    required: true,
  },
});

const { public: config } = useRuntimeConfig();
const isNative = useState('native');
const videoAd = inject('videoAd') as Boolean;

const adId = props.componentContent.count;

const playlistHeader: any = ref('');
const playlistLoaded = (e: any) => {
  playlistHeader.value = e.feedData.title || '';
};

const playerSetupOverride = {
  playlist: `https://cdn.jwplayer.com/v2/playlists/${config.jwPlayer.playlists.mostViewed}`,
  autostart: 'viewable',
  mute: true,
  displaydescription: false,
  nextUpDisplay: false,
  related: {
    displayMode: 'none',
    autoplayTimer: 5,
    oncomplete: 'autoplay',
  },
  autoPause: {
    viewability: false,
    pauseAds: false,
  },
  floating: {
    dismissible: true,
  },
  skin: null, // Ensure we don't get things from JW settings that messes up the styling.
};
</script>