<template>
  <div>
    <template v-for="contentComponent in contentComponents">
      <ContentWysiwyg :component-content="contentComponent" v-if="contentComponent.type === 'text'" class="mb-13" />
      <ContentAd v-else-if="contentComponent.type === 'advert'" :componentContent="contentComponent" />
      <LazyContentCTA v-else-if="contentComponent.type === 'cta'" :componentContent="contentComponent" class="overflow-hidden" />
      <LazyContentGlobalLink v-else-if="contentComponent.type === 'globalLink'" :componentContent="contentComponent" class="overflow-hidden" />
      <LazyContentQuote v-else-if="contentComponent.type === 'quote'" :componentContent="contentComponent" class="overflow-hidden" />
      <LazyContentFactbox v-else-if="contentComponent.type === 'factbox' || contentComponent.type === 'globalFactbox'" :componentContent="contentComponent" class="overflow-hidden" />
      <LazyContentHighlightedText v-else-if="contentComponent.type === 'highlightedText'" :componentContent="contentComponent" class="overflow-hidden" />
      <LazyContentGallery v-else-if="contentComponent.type === 'gallery'" :componentContent="contentComponent" class="overflow-hidden" />
      <LazyContentRelated v-else-if="contentComponent.type === 'relatedContent'" :componentContent="contentComponent" class="overflow-hidden" :position="getRelatedContentPosition(contentComponent)" />
      <LazyContentImage v-else-if="contentComponent.type === 'image'" :componentContent="contentComponent" class="overflow-hidden" />
      <AllerVisibilityLoader v-else-if="contentComponent.type === 'embed'">
        <LazyContentEmbed :componentContent="contentComponent" class="overflow-hidden" />
      </AllerVisibilityLoader>
      <AllerVisibilityLoader v-else-if="contentComponent.type === 'video'">
        <LazyContentVideo :componentContent="contentComponent" class="mb-30 pb-40 overflow-hidden" />
      </AllerVisibilityLoader>
      <AllerVisibilityLoader v-else-if="contentComponent.type === 'jwplayerLive'">
        <LazyVideoWrapper :media="contentComponent.jwplayer_live" class="mb-30 pb-40 overflow-hidden" />
      </AllerVisibilityLoader>
      <AllerVisibilityLoader v-else-if="contentComponent.type === 'livecenter'">
        <LazyContentLiveBlog :component-content="contentComponent" class="overflow-hidden" />
      </AllerVisibilityLoader>
      <LazyContentDocument :content-component="contentComponent" v-if="contentComponent.type === 'document'" />
    </template>
  </div>
</template>

<script setup lang="ts">
const props = defineProps({
  contentComponents: {
    type: Object as PropType<Aller.ContentComponents[]>,
    required: true,
  },
});

const relatedContentList = props.contentComponents.filter((contentComponent) => contentComponent.type === 'relatedContent') as Aller.ContentComponentRelated[];

// Get the position of the related content in relation the other related content elements.
// This is required for tracking purposes.
const getRelatedContentPosition = (contentComponent: Aller.ContentComponent) => {
  return relatedContentList.findIndex((item) => item.relatedContent.id === (contentComponent as Aller.ContentComponentRelated).relatedContent.id) + 1;
};
</script>
